
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$orderconfiguration-title-padding-y: $spacer * 0.85 !default;
$orderconfiguration-title-padding-x: $spacer * 1 !default;
$orderconfiguration-title-bg: $beige !default;
$orderconfiguration-title-font-size: $font-size-lg !default;
$orderconfiguration-title-font-weight: $font-weight-bold !default;
$orderconfiguration-title-color: inherit !default;

$orderconfiguration-list-gap: $spacer * 3 !default;
$orderconfiguration-list-padding-y: $spacer * 0.5 !default;
$orderconfiguration-list-padding-x: $orderconfiguration-title-padding-x !default;
$orderconfiguration-list-border: map-get($borders, 'base') !default;
$orderconfiguration-list-title-font-size: $font-size-sm !default;
$orderconfiguration-list-title-font-weight: inherit !default;
$orderconfiguration-list-title-color: $dark !default;
$orderconfiguration-list-data-font-size: inherit !default;
$orderconfiguration-list-data-font-weight: inherit !default;
$orderconfiguration-list-data-color: inherit !default;
$orderconfiguration-list-data-content-gap: $spacer * 0.3 !default;

.order-configuration {

  .summary-title,
  .configuration-title {
    margin: 0;
    padding: $orderconfiguration-title-padding-y $orderconfiguration-title-padding-x;
    background-color: $orderconfiguration-title-bg;
    font-size: $orderconfiguration-title-font-size;
    font-weight: $orderconfiguration-title-font-weight;
    color: $orderconfiguration-title-color;
  }

  .summary-list {
    margin-bottom: $orderconfiguration-list-gap;
    border-bottom: $orderconfiguration-list-border;

    > div {
      padding: 0 $orderconfiguration-list-padding-x;

      &.summary-separator {
        padding: $orderconfiguration-list-padding-y 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-top: 0;
        }

        hr {
          margin: 0;
          border-color: nth($orderconfiguration-list-border, 3);
        }
      }

      &.summary-price {
        &.is-total {
          margin-top: $orderconfiguration-list-padding-y;
          padding: $orderconfiguration-list-padding-y $orderconfiguration-list-padding-x;
          border-top-color: nth($orderconfiguration-list-border, 3);
        }
      }
    }
  }

  .configuration-list {
    @include make-row(0);
    margin-bottom: $orderconfiguration-list-gap;
    border-top: $orderconfiguration-list-border;

    dt,
    dd {
      @include make-col-ready(0);
      margin: 0;
      padding: $orderconfiguration-list-padding-y $orderconfiguration-list-padding-x;
      border-bottom: $orderconfiguration-list-border;
    }

    dt {
      @include make-col(3);
      font-size: $orderconfiguration-list-title-font-size;
      font-weight: $orderconfiguration-list-title-font-weight;
      color: $orderconfiguration-list-title-color;
    }

    dd {
      @include make-col(9);
      font-size: $orderconfiguration-list-data-font-size;
      font-weight: $orderconfiguration-list-data-font-weight;
      color: $orderconfiguration-list-data-color;

      > * {
        margin-bottom: $orderconfiguration-list-data-content-gap;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
