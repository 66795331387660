
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$addressline-padding-y: $spacer * 0.5 !default;
$addressline-padding-x: 0 !default;
$addressline-border: map-get($borders, 'base') !default;

.address-line {
  padding: $addressline-padding-y $addressline-padding-x;
  border-top: $addressline-border;
  border-bottom: $addressline-border;

  + .address-line {
    margin-top: nth($addressline-border, 1) * -1;
  }

  &.inline {
    padding: 0;
    border: 0 none;

    + .address-line {
      margin-top: 0;
    }
  }
}
