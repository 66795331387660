
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$checkoutoptionform-padding-y: $spacer * 1.25 !default;
$checkoutoptionform-padding-x: $checkoutoptionform-padding-y !default;
$checkoutoptionform-border: map-get($borders, 'bold') !default;
$checkoutoptionform-border-radius: $border-radius-lg !default;

$checkoutoptionform-control-font-size: $font-size-lg !default;
$checkoutoptionform-control-font-weight: $font-weight-bold !default;

$checkoutoptionform-content-offset: $b-custom-control-indicator-size-lg + $custom-control-gutter !default;
$checkoutoptionform-content-gap: $spacer * 0.5 !default;
$checkoutoptionform-content-padding-y: 0 !default;
$checkoutoptionform-content-padding-x: 0 !default;

.checkout-option-form {
  padding: $checkoutoptionform-padding-y $checkoutoptionform-padding-x;
  border: $checkoutoptionform-border;
  border-radius: $checkoutoptionform-border-radius;

  .option-form-control {
    font-size: $checkoutoptionform-control-font-size;
    font-weight: $checkoutoptionform-control-font-weight;

    .custom-control-input {
      ~ .custom-control-label {
        margin: 0;
      }
    }
  }

  .option-form-content {
    margin-left: $checkoutoptionform-content-offset;
    margin-top: $checkoutoptionform-content-gap;
    padding: $checkoutoptionform-content-padding-y $checkoutoptionform-content-padding-x;
  }

  .custom-control {
    z-index: unset;
  }

  &.no-radio {
    .option-form-content {
      margin-left: 0;
    }
  }

  + .checkout-option-form {
    border-top: 0 none;
  }

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
