
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$checkoutstep-alert-heading-border: set-nth(map-get($borders, 'base'), 3, theme-color-level('danger', $alert-border-level)) !default;
$checkoutstep-alert-heading-border-gap: $spacer * 0.5 !default;
$checkoutstep-alert-errorgroup-padding-y: $spacer * 0.5 !default;
$checkoutstep-alert-errorgroup-padding-x: 0 !default;

.checkout-step {
  @include component('y');

  .alert {
    .alert-heading {
      padding-bottom: $checkoutstep-alert-heading-border-gap;
      border-bottom: $checkoutstep-alert-heading-border;
    }

    .error-group {
      padding: $checkoutstep-alert-errorgroup-padding-y $checkoutstep-alert-errorgroup-padding-x;

      .error {}

      &:first-child { padding-top: 0; }
      &:last-child { padding-bottom: 0; }
    }
  }
}
