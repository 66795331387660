
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$checkoutcoupon-padding-y: $spacer * 1.25 !default;
$checkoutcoupon-padding-x: $checkoutcoupon-padding-y !default;
$checkoutcoupon-border: map-get($borders, 'bold') !default;
$checkoutcoupon-border-radius: $border-radius-lg !default;

.checkout-coupon {
  padding: $checkoutcoupon-padding-y $checkoutcoupon-padding-x;
  border: $checkoutcoupon-border;
  border-radius: $checkoutcoupon-border-radius;

  position: relative;

  .loader-container {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(204, 204, 204, 0.8);

    .loader {
      height: 100%;
    }
  }

  .coupon-registered {
    ul {
      @include list-unstyled();
    }

    .redeemed-coupon-list {
      .redeemed-coupon {
        position:relative;

        .coupon-code {
          width: 25%;
        }

        .coupon-description {
        }

        .coupon-action {
          a {
            cursor: pointer;
          }
        }
      }
    }

    @include media-breakpoint-down(get-breakpoint-key($tablet-breakpoint, -2)) {
      .redeemed-coupon-list {
        .redeemed-coupon {
          padding-bottom: $checkoutcoupon-padding-y;
          margin-bottom: $checkoutcoupon-padding-y;
          border-bottom: $checkoutcoupon-border;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
          }

          .coupon-code {
            width: 100%;
          }

          .coupon-action {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }
  }
}
