
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$checkoutuseraddresses-address-gap: $spacer * 0.25 !default;
$checkoutuseraddresses-address-padding-y: $spacer * 0.75 !default;
$checkoutuseraddresses-address-padding-x: $checkoutuseraddresses-address-padding-y !default;
$checkoutuseraddresses-address-bg: $beige !default;
$checkoutuseraddresses-address-border-radius: $border-radius !default;
$checkoutuseraddresses-address-line-height: 1.2 !default;

$checkoutuseraddresses-address-add-color: $link-color !default;
$checkoutuseraddresses-address-add-text-decoration: $link-decoration !default;

$checkoutuseraddresses-address-form-gap: $spacer !default;

.checkout-user-addresses {
  .user-address {
    padding: $checkoutuseraddresses-address-padding-y $checkoutuseraddresses-address-padding-x;
    background-color: $checkoutuseraddresses-address-bg;
    border-radius: $checkoutuseraddresses-address-border-radius;
    line-height: $checkoutuseraddresses-address-line-height;

    .custom-radio {
      min-height: $checkoutuseraddresses-address-line-height * 1rem;

      .custom-control-input {
        ~ .custom-control-label {
          &:before,
          &:after {
            top: 0;
          }
        }
      }
    }

    &.radio-centered {
      .custom-radio {
        .custom-control-input {
          ~ .custom-control-label {
            margin: 0;

            &:before,
            &:after {
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    .address-add {
      color: $checkoutuseraddresses-address-add-color;
      text-decoration: $checkoutuseraddresses-address-add-text-decoration;
    }

    .address-add-form {
      margin-top: $checkoutuseraddresses-address-form-gap;
    }

    + .user-address {
      margin-top: $checkoutuseraddresses-address-gap;
    }
  }
}
